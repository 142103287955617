.stats {
    &__header {

        display: flex;
        justify-content: space-between;
        padding: 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__main {
            display: flex;
        }

        h4 {
            padding-top: 1rem;
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 1px solid $color-black;
        }

        &__box {

            display: flex;

            p {
                padding: 1rem;

            }
        }

        &__btn {
            background-color: $color-secondary-light;

            display: flex;
            justify-content: center;
            align-items: center;

            span {}

            &__icon {
                height: 2rem;
                width: 2rem;
                margin-left: 2rem;

            }

        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 3rem 5vw;
        gap: 8rem;


        @include respond(small-desktop) {
            padding: 3rem 2rem;
            gap: 5rem;

        }

        @include respond(tab-land) {
            flex-direction: column;

        }

        &__pitch {
            flex: 1.5;

            width: 100%;

            @include respond(tab-land) {
                width: 80%;
            }

            @include respond(phone-large) {
                width: 90%;
            }



            &__home {

                &__head {
                    display: flex;

                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    background-color: green;
                    color: white;
                    padding: 5px;

                    &__team {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row-reverse;

                        &__name {
                            margin-left: 2rem;
                            font-weight: bold;
                        }

                        &__badge {
                            width: 4rem;
                            height: auto;
                        }
                    }

                    &__formation {
                        font-weight: bold;
                    }
                }

                &__imgbox {}

                &__img {
                    max-width: 100%;
                    height: auto;
                }

            }
        }


        &__metrics {
            flex: 1.3;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;


            // Make the last sub flex to the end thus the away team lineup

            @include respond(tab-land) {
                width: 80%;

            }

            @include respond(phone-large) {
                width: 90%;

            }


            &__label {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-self: center;
                margin-bottom: 3rem;

                &--last {
                    justify-content: flex-end;
                }



                &__badge {
                    width: 6rem;
                    height: 7rem;
                }
            }

            &__subs {

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                &--last {

                    @include respond(phone-large) {
                        align-items: flex-end;
                    }
                }


                &__header {

                    &--small {
                        display: none;
                        width: 0;

                        @include respond(phone-large) {
                            display: block;
                            width: auto;
                            margin-bottom: 1.5rem;
                            
                        }

                    }

                    &--large {
                        @include respond(phone-large) {
                            display: none;
                            width: 0;
                        }

                    }
                }




                &__container {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 3rem;

                    @include respond(phone-large) {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    h4 {
                        margin-top: 3rem;
                    }

                }

                &__itemwrap {

                    @include respond(phone-large) {
                        width: 100%;
                        padding: 1rem;
                    }


                }

                &__last li {
                    // width: 100%;
                    text-align: right;


                }


                li {
                    list-style: none;
                    margin-bottom: 1.5rem;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;


                }

                &__jersey {
                    font-weight: bold;
                }

                &__name {}

                &__icon {
                    color: green;
                }
            }

            &__managers {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;

                &__box {

                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    p {}

                }

                h4 {
                    margin: 1.3rem 0;
                }

            }

            &__kpis {
                width: 100%;
                margin-top: 2rem;


                h4 {
                    text-align: center;
                    margin: 1.3rem 0;
                }

                &__box {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                &__values {
                    text-align: center;

                    li {
                        list-style: none;
                        font-weight: bold;
                        margin-bottom: 1rem;
                    }

                }

                &__labels {
                    text-align: center;
                    // font-size: 1.35rem;

                    li {
                        list-style: none;
                        margin-bottom: 1rem;
                    }

                }
            }
        }
    }


}