.news {

    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 4rem 5rem;
    background-color: $color-black;
    color: white;

    &__container {
        background-color: white;
        color: black;
    }

    @include respond(phone-large) {
        padding: 4rem 1.6rem;
    }


    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__main {
            display: flex;
        }

        h4 {
            padding-top: 1rem;
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 1px solid $color-white;

            @include respond(phone-large) {
                padding-right: 1rem;
                margin-right: 1rem;
            }

        }

        &__box {

            display: flex;

            @include respond(phone-large) {
                display: none;
                width: 0;
            }

            p {
                padding: 1rem;

            }
        }

        &__btn {
            background-color: $color-primary-dark;
        }

    }

    &__body {


        &__main {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            grid-column-gap: 1.5rem;
            margin-bottom: 4rem;

            @include respond(phone-large) {
                gap: 1.5rem;
                flex-direction: column;
            }

            &__imgbox {}

            &__img {
                width: 40vw;
                height: auto;
                object-fit: cover;

                @include respond(phone-large) {
                    width: 90vw;
                }

            }

            &__textbox {

                &__head {

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 3rem;

                    @include respond(phone-large) {
                        margin-bottom: 1rem;
                    }

                    h4 {
                        padding: 3px;
                        padding-left: 0;
                        padding-right: 1rem;
                        margin-right: 1rem;

                        border-right: 1px solid $color-black;
                        color: $color-secondary-dark;

                    }

                    span {}
                }

                &__content {
                    font-size: 1.8rem;
                    font-weight: 700;

                    @include respond(phone-large) {
                        font-size: 1.1rem;
                        font-weight: 400;
                    }
                }
            }

        }

        &__sub {
            width: 100%;

            display: flex;
            flex-wrap: wrap;
            grid-column-gap: 1.5rem;
            cursor: pointer;


            align-items: center;
            justify-content: flex-start;

            &__centered {
                justify-content: center;
            }

            @include respond(phone-large) {
                flex-direction: column;
            }



            &__item {
                flex: 0 0 23%;

                margin-bottom: 3rem;

                padding: 2rem;
               

                @include respond(phone-large) {
                    margin-bottom: 5rem;
                    min-width: 0;
                    border: 1px solid $color-white;
                }


                &__imgbox {}

                &__img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }

                &__textbox {

                    &__head {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 1.5rem;

                        h4 {
                            padding: 3px;
                            padding-left: 0;
                            padding-right: 1rem;
                            margin-right: 1rem;

                            border-right: 1px solid $color-black;
                            color: $color-secondary-dark;
                        }

                        span {}
                    }

                    &__content {
                        font-size: 1.2rem;
                        font-weight: 700;

                        @include respond(phone-large) {
                            font-weight: 400;
                        }
                    }
                }

            }
        }
    }
}