.seasondrop {
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 3rem 1rem;

    &__input {
        width: auto;
        font-size: 1.55rem;
        padding: 1.5rem;
        font-family: inherit;
        font-weight: bold;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    }
}