.singleplayer {

    &__landing {
        background-color: $color-primary;
        height: 80vh;
        padding: 4rem 8vw;
        overflow: hidden;

        @include respond(tab-land) {
            height: auto;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // overflow: hidden;

            @include respond(tab-land) {
                flex-direction: column;
            }

            &__left {

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @include respond(tab-land) {
                    margin-bottom: 3rem;
                }

                &__details {

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;

                    &__number {
                        font-size: 8rem;
                        font-weight: 500;
                    }

                    &__name {
                        margin-left: 2rem;

                        span {
                            display: block;
                            font-size: 3.5rem;
                            font-weight: bold;
                        }

                    }
                }

                &__position {
                    margin-top: 2rem;
                    color: $color-secondary-dark;
                    font-size: 2rem;
                }

            }

            &__right {

                img {
                    height: auto;
                    max-width: 100%;
                }

            }
        }
    }

    &__profile {

        margin: 5rem 10vw;
        font-size: 1.6rem;

        

        @include respond(tab-land) {
            margin: 4rem 2rem;
        }


        &__stats {

            width: 100%;
            display: flex;
            gap: 2.5rem;

            @include respond(tab-land) {
                flex-direction: column;
                justify-content: center;
                // align-items: center;
            }

            &__item {
                flex: 1;
                flex-basis: 30%;
                height: 430px;

                &__head {
                    font-weight: bold;
                    font-size: 1.6rem;
                }

                &__body {
                    background-color: $color-grey-light-1;
                    padding: 4rem 2rem;

                    &__personal {
                        

                        &__record {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2rem;

                            &__title {
                                margin-bottom: 5px;
                                color: $color-secondary-dark;
                                font-size: 1.5rem;
                                font-weight: 600;
                            }

                            &__value {
                                font-size: 1.8rem;
                                font-weight: bold;
                            }
                        }

                    }

                    &__statistics {
                        

                        &__record {
                            display: flex;
                            margin-bottom: 2rem;


                            &__title {
                                flex: 1;
                                font-size: 1.4rem;
                                color: $color-secondary-dark;
                                word-wrap: break-word;
                                font-weight: 600;
                                
                            }

                            &__value {
                                flex: 3;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                font-weight: bold;
                                font-size: 3.5rem;


                            }
                        }

                    }

                    &__honours {
                        

                        &__trophy {

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 3rem;

                            &__img {
                                width: 10rem;
                                height: 10rem;
                                margin-right: 1.5rem;
                            }

                            &__details {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;

                                &__cups {
                                    font-size: 4rem;
                                }

                                &__title {
                                    font-size: 2rem;
                                    color: $color-secondary-dark;
                                    font-weight: bold;
                                }
                            }
                        }

                        &__record {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 1.2rem;

                            &__title {
                                flex: 3;
                                font-size: 1.8rem;
                            }

                            &__value {
                                flex: 1;
                                font-weight: bold;
                                font-size: 2.5rem;
                            }
                        }

                    }
                }

            }
        }

        &__bio {

            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 2rem;
            margin-bottom: 8rem;
            margin-top: 5rem;

            @include respond(tab-land) {
                flex-direction: column;
            }

            &__title {
                flex: 1;
                font-weight: bold;
            }

            &__text {
                flex: 2;

            }
        }

        &__otherplayers {
            margin-top: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5rem -8vw;

            @include respond(tab-land) {
                flex-direction: column;
                gap: 2.5rem;
                margin: 2rem;
                
            }
        }
    }
}