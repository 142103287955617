.matches {

    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 4rem 5rem;

    @include respond(phone-large) {
        padding: 4rem 1.6rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__main {
            display: flex;
        }

        h4 {
            padding-top: 1rem;
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 1px solid $color-black;

            @include respond(phone-large) {
                padding-right: 1rem;
                margin-right: 1rem;
            }
        }

        &__box {

            display: flex;

            @include respond(phone-large) {
                display: none;
                width: 0;
            }

            p {
                padding: 1rem;

            }
        }

        &__btn {
            background-color: $color-primary-dark;
        }

    }

    &__container {
        flex: 1;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-column-gap: 2rem;

        @include respond(tab-land) {
            flex-direction: column;
            gap: 3rem;
        }

    }

    &__played {
        flex: 1.2;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include respond(tab-land) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 100%;
        }

        &__head {

            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 5px;
            background-color: $color-primary-dark;
            color: $color-white;
            margin-bottom: 5px;


            &__datebox {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__monthbox {}

            &__dayofmonth {
                font-size: 2rem;
                font-weight: 700;
                margin-right: 1rem;
            }

            &__day {
                font-weight: 600;

            }

            &__month {}

            &__title {}
        }



        &__body {

            display: flex;
            width: 100%;
            justify-content: space-around;
            padding: 3rem 0;

            &__team {

                &__logo {

                    height: 100px;
                    width: 79px;

                    @include respond(small-desktop) {
                        height: 6rem;
                        width: 6rem;
                    }
                }

            }

            &__result {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &__trophy {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &__icon {
                        width: 3rem;
                        height: 3rem;
                    }
                }

                &__score {
                    padding: 1rem 3rem;
                    font-size: 1.5rem;
                    font-weight: 700;
                    background-color: $color-secondary-dark;
                    margin: 1rem 0;
                    border-radius: 5px;
                    color: $color-white;

                    &__myteam {}

                    &__colon {
                        margin: 0 5px;

                    }

                    &__otherteam {}
                }

                &__stadium {
                    text-align: center;
                }

            }
        }

        &__btnbox {
            display: flex;
            justify-content: space-between;
            width: 100%;
            grid-column-gap: 5px;
        }

        &__btn {
            color: $color-black;
            font-size: 1.3rem;
            flex: 1;

            &:hover {
                font-weight: bold;
            }
        }
    }

    &__upcoming {
        flex: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(phone-large) {
            width: 100%;
        }

        &__countdown {

            display: flex;
            width: 100%;

            &__item {
                flex: 1;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &__value {
                    font-size: 2rem;
                    font-weight: bold;
                }

                &__label {
                    font-size: 1rem;
                }
            }
        }
    }

    &__table {
        flex: 1.2;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__container {
            width: 100%;
        }

        &__head {
            display: flex;
            justify-content: space-between;
            padding: 1rem 5px;
            width: 100%;
            background-color: $color-primary-dark;
            color: $color-white;
            margin-bottom: 5px;

            &__trophy {
                display: flex;
                justify-content: center;
                align-items: center;

                &__icon {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

            &__title {}
        }

        &__row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: .8rem 0;


            &__img {
                width: 25px;
                height: 25px;
                margin: 0 1rem;
            }
        }

        &__labels {
            display: flex;
            width: 100%;

            &__left {
                flex: 0 0 40%;

                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &:nth-child(2) {
                    // margin: 0 1rem;
                }
            }

            &__right {
                flex: 1;

                display: flex;
                justify-content: center;
                align-items: center;



                span {
                    width: 3rem;

                    text-align: center;

                }
            }

        }
    }

}