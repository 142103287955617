.gallery {

    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-direction: column;
    padding: 4rem 5rem;

    @include respond(tab-land) {
        padding: 4rem 3rem;
    }

    @include respond(phone-large) {
        padding: 4rem 1.5rem;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 3rem 0;
        cursor: pointer;


        img {
            width: 25rem;
            height: 15rem;
            object-fit: cover;
        }

        p {
            margin-top: 1rem;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

    }

    &__imgrodal {
        object-fit: contain;
    }
}