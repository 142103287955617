.shop {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 7rem 5rem;
    background-color: $color-black;
    color: white;

    @include respond(phone-large) {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    &__intro {
        flex: 1;
        padding: 2rem 4rem;

        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        & > * {
            margin-bottom: 2.5rem;
        }

        &__headbtn {
            background-color: $color-primary-dark;
            color: white;

        }

        h2 {
            
        }

        p {

        }

    }

    &__btn {
        background-color: $color-secondary-dark;
        
    }

    &__slidebox {
        flex: 1;
        background-color: $color-black;

        display: flex;
        justify-content: center;
        align-items: center;
        
    }
}