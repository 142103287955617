.landing {
    height: 100vh;
    display: flex;
    
    background-size: cover;
    // background-color: $color-black;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/gallery%2F12.jpg?alt=media&token=f54707e1-b5db-451f-899c-f7b5a651a1e8');
    
    // background-image: url('../img/Cover.jpg');
    @include respond(tab-land) {
        height: 80vh;
    }

    @include respond(phone-large) {
        height: 68vh;
    }

    &__textsection {
        flex: 1;
        height: 100%;
        background-color: rgba($color-black, 0.3);
        
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        color: white;

        &__main {
            font-family: 'Inknut Antiqua';
            font-size: 6rem;
            // word-spacing: 50rem;
            // text-align: center;
            

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin: 3rem 0;
            text-transform: uppercase;

            @include respond(tab-land) {
                font-size: 4rem;
            }

            @include respond(phone-large) {
                font-size: 2.3rem;
            }

            p {
                display: block;


                span {
                    padding: 0 1rem;
                    color: $color-secondary-dark;
                }
            }
        }

        &__sub {
            font-size: 2rem;

        }

        &__footer {
            padding-bottom: 2px;
            font-size: 1.5rem;
            border-bottom: 2px double $color-secondary-dark;
        }

        &__ball {
            width: 3rem;
            height: 3rem;
            color: $color-secondary-dark;
            
        }

    }

    &__background {
        flex: 1;

        height: 100%;
        // background-image: url(../img/background1.png);
        background-size: cover;

        @include respond(tab-land) {
            flex: 0.5;
        }

        @include respond(phone-large) {
            flex: 0.2;
        }

    }
}