.players {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 4rem 5rem;
    padding-bottom: 1rem;

    @include respond(phone-large) {
        padding: 4rem 1.6rem;
    }


    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__main {
            display: flex;
        }

        h4 {
            padding-top: 1rem;
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 1px solid $color-black;

            @include respond(phone-large) {
                padding-right: 1rem;
                margin-right: 1rem;
            }
        
        }

        &__box {

            display: flex;

            @include respond(phone-large) {
                display: none;
                width: 0;
            }

            p {
                padding: 1rem;

            }
        }

        &__btn {
            background-color: $color-primary-dark;
        }
    }

    &__body {
        display: flex;
        grid-column-gap: 3rem;
        
        @include respond(phone-large) {
            flex-direction: column;
        }
    }

    &__item {
        flex: 0 0 30%;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        @include respond(phone-large) {
            margin-bottom: 3rem;
            flex: 1;

            &:last-child {
                margin-bottom: 0;
            }
            


        }

        &:hover > &__img {
            transform: translateX(0);
        }

        &__details {
            position: absolute;
            top: 0;
            left: 5px;

            &__name {
                display: flex;
                flex-direction: column;

                span {
                    font-size: 2rem;
                    text-transform: capitalize;
                }
            }

            &__position {
                font-size: 1.7rem;
                margin: 1rem 0;

            }

            &__number {
                font-size: 4rem;

            }
        }

        &__img {
            width: 90%;
            height: auto;
            transition: all .2s ease;

            transform: translateX(7rem);

        }
    }
}