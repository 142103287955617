.allplayers {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;

    &__banner {
        width: 100%;
        background-color: $color-primary;
        padding: 3rem 0;
        text-align: center;

        span {
        
            font-size: 2rem;
            border-bottom: 2px solid #fff;
            color: white;
        }

    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__main {
            display: flex;
        }

        h4 {
            padding-top: 1rem;
            padding-right: 2rem;
            margin-right: 2rem;
            border-right: 1px solid $color-black;
        }

        &__box {

            display: flex;

            @include respond(phone-large) {
                display: none;
                width: 0;
            }

            p {
                padding: 1rem;

            }
        }

        &__btn {
            background-color: $color-primary-dark;
        }
    }

    &__body {
        padding: 4rem 5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;

        
        @include respond(phone-large) {
            flex-direction: column;
        }

    }

    &__item {
        flex: 0 0 30%;

        
        margin-bottom: 5rem;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        
        @include respond(tab-land) {
            flex: 0 0 45%;
        }

        @include respond(phone-large) {
            
            flex: 1;
        }

        

        &:hover>&__img {
            transform: translateX(0);
        }

        &__details {
            position: absolute;
            top: 0;
            left: 5px;

            &__name {
                display: flex;
                flex-direction: column;

                span {
                    font-size: 2rem;
                }
            }

            &__position {
                font-size: 1.7rem;
                margin: 1rem 0;

            }

            &__number {
                font-size: 4rem;

            }
        }

        &__img {
            width: 90%;
            height: auto;
            transition: all .2s ease;

            transform: translateX(7rem);

        }
    }

    &__managers {
        padding: 4rem 5rem;
        width: 100%;

        h4 {
            margin-bottom: 2.5rem;
            font-size: 1.5rem;
        }


        &__body {
            width: 100%;
            display: flex;

            flex-wrap: wrap;

            justify-content: center;

        }


        &__item {

            flex: 0;

            flex-basis: 30%;
            margin-bottom: 5rem;
            position: relative;
            overflow: hidden;


            @include respond(tab-land) {
                flex-basis: 42%;
            }
    
            @include respond(phone-large) {
                flex-basis: 90%;
                // flex: 1;
            }



            &__name {
                
                margin-top: 2rem;
                font-weight: bold;

                & > :first-child {
                    margin-right: 1rem;
                }

                span {
                    font-size: 1.5rem;
                    color: $color-grey-dark-3;

                }
            }



            &__img {
                width: 90%;
                height: auto;
                min-height: 20rem;
                transition: all .2s ease;

            }

        }
    }
}