.spinner {
    display: flex;
    height: 65vh;
    width: 98vw;

    justify-content: center;
    align-items: center;
    // position: absolute;
    // top: 0;
    // left: 0;

    // background-color: black;
}