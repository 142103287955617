// VARIABLES
$color-primary: #333399;


$color-primary-light: #1560bd;
$color-primary-dark: #00008b;

$color-secondary-light: #ff7730;
$color-secondary-dark: #ff4040;

$color-tertiary-light: #1e4d2b;
$color-tertiary-dark: #087830;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.4rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// PADDING
$body-padding-large: 5rem 10rem;
$body-padding-medium: 5rem 7rem;
$body-padding-small: 5rem 4rem;
$body-padding-xtra-small: 5rem 2rem;

$header-big: 6rem;
$header-medium: 4rem;

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

/*
.Toastify {
  &__toast-body {
    font-family: Arial, Helvetica, sans-serif;
    
    font-size: 1.3rem !important;
    
  }
}
*/


@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin respond($breakpoint) {

  @if $breakpoint ==phone-small {
    @media only screen and (max-width: 20em) {
      @content
    }

    ; // 320px
  }

  @if $breakpoint ==phone-medium {
    @media only screen and (max-width: 23.4375em) {
      @content
    }

    ; // 375px
  }

  @if $breakpoint ==phone-large {
    @media only screen and (max-width: 26.5625em) {
      @content
    }

    ; // 425px
  }

  @if $breakpoint ==tab-land {
    @media only screen and (max-width: 48em) {
      @content
    }

    ; // 768px
  }


  @if $breakpoint ==small-desktop {
    @media only screen and (max-width: 64em) {
      @content
    }

    ; // 1024px
  }

  @if $breakpoint ==small-desktop-less {
    @media only screen and (max-width: 90em) {
      @content
    }

    ; // 1024px
  }

  @if $breakpoint ==big-desktop {
    @media only screen and (min-width: 90em) {
      @content
    }

    ; // 1440px
  }



  @if $breakpoint ==4k {
    @media only screen and (min-width: 160.00em) {
      @content
    }

    ; // 2560px
  }

  @if $breakpoint ==4kultra {
    @media only screen and (min-width: 175.00em) {
      @content
    }

    ; // 2800px
  }
}


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;


  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  font-family: 'Sora';
  font-size: $default-font-size;
}

@keyframes drop {
  0% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0);
  }
}

.phoneview {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  padding: 0 5rem;


  img {
    width: 10rem;
    height: 10rem;
    margin-bottom: 2.5rem;
  }

  p {
    font-weight: 600;
    text-align: center;

  }
}

.imgrodal {

  &__img {
    object-fit: contain;
  }
}

#animated-modal-container {
  opacity: 0.99 !important;
}

#animated-modal-background {
  opacity: 0.99 !important;
}