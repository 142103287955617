.history {

    &__landing {

        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        background-image: linear-gradient(rgba($color-primary-dark, 0.6), rgba($color-primary-light, 0.7)), url('https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/gallery%2F3.jpg?alt=media&token=e3f70e87-b27e-459f-9abe-ee96b3be1d83');

        background-repeat: no-repeat;
        background-size: cover;

        &__team {
            font-size: 3rem;
            color: white;

            @include respond(phone-large) {
                font-size: 2rem;
            }
            &__year {

                color: $color-secondary-dark;
                font-weight: bold;
            }

            &__title {
                font-weight: bold;
            }

            &__club {
                font-weight: bold;
            }
        }

        &__logobox {}

        &__logo {
            width: 15rem;
            height: auto;

        }
    }

    &__profile {
        margin-bottom: 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 5rem 10vw;

        @include respond(tab-land) {
            padding: 4rem 8rem;

        }

        @include respond(phone-large) {
            padding: 4rem 2rem;
        }

        &__stats {

            width: 100%;
            display: flex;
            gap: 2.5rem;

            @include respond(tab-land) {
                flex-direction: column;
            }

            &__subitem {
                width: 100%;

                &__container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    



                    & > * {
                        flex: 1;
                    }

                    @include respond(tab-land) {
                        flex-direction: column;
                    }
                }
            }

            &__item {
                flex: 1;
                flex-basis: 30%;
                height: 460px;

                &--big {
                    flex: 2;

                    
                }

                &--small {
                    flex: 1;
                }

                &__head {
                    font-weight: bold;
                    font-size: 1.6rem;
                }

                &__body {
                    background-color: $color-grey-light-1;
                    padding: 4rem 2rem;

                    &__personal {


                        &__record {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2rem;

                            &__title {
                                margin-bottom: 5px;
                                color: $color-secondary-dark;
                                font-size: 1.5rem;
                                font-weight: 600;
                            }

                            &__value {
                                font-size: 1.8rem;
                                font-weight: bold;
                            }
                        }

                    }

                    &__statistics {


                        &__record {
                            display: flex;
                            margin-bottom: 2rem;


                            &__title {
                                flex: 1;
                                font-size: 1.4rem;
                                color: $color-secondary-dark;
                                word-wrap: break-word;
                                font-weight: 600;

                            }

                            &__value {
                                flex: 3;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                font-weight: bold;
                                font-size: 3.5rem;


                            }
                        }

                    }

                    &__honours {


                        &__trophy {

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 3rem;

                            &__img {
                                width: 10rem;
                                height: 10rem;
                                margin-right: 1.5rem;
                            }

                            &__details {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                text-align: center;

                                &__cups {
                                    font-size: 4rem;
                                }

                                &__title {
                                    font-size: 2rem;
                                    color: $color-secondary-dark;
                                    font-weight: bold;
                                }
                            }
                        }

                        &__record {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 1.2rem;

                            &__title {
                                flex: 3;
                                font-size: 1.6rem;
                            }

                            &__value {
                                flex: 1;
                                font-weight: bold;
                                font-size: 2.2rem;
                            }
                        }

                    }
                }
            }
        }
    }

    &__content {

        display: flex;
        gap: 2rem;
        padding: 5rem 10vw;

        @include respond(tab-land) {
            flex-direction: column;
            padding: 5rem;
        }

        @include respond(tab-land) {
            padding: 3rem 2rem;
        }
        

        &__section {
            flex: 1;
            display: flex;
            gap: 3rem;

            @include respond(tab-land) {
                flex-direction: column;
            }

            &__head {

            }

            &__body {


                &--history {

                    p {
                        margin-bottom: 2rem;

                        &::first-letter {
                            font-size: 2rem;
                            color: $color-primary-dark;
                            font-weight: bold;
                        }
                    }
                }

            }

            &__item {
                display: flex;
                gap: 0.5rem;
                margin-bottom: 1.5rem;

                @include respond(phone-large) {
                    flex-direction: column;
                    gap: 1.4rem;
                    margin-bottom: 2.5rem;
                }

                &__box {
                    flex: 1;
                    display: flex;

                    
                }

                &__year {
                    color: $color-secondary-dark;

                    @include respond(phone-large) {
                        font-size: 1.5rem;
                    }

                }

                &__iconbox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                }

                &__icon {
                    margin-bottom: 5px;
                    color: $color-secondary-dark;
                }

                &__border {
                    flex: 1;

                    height: auto;
                    width: 2px;
                    background-color: $color-secondary-dark;

                    

                }

                &__body {

                    h2 {
                        font-size: 1.8rem;

                        @include respond(phone-large) {
                            font-size: 1.45rem;
                        }
                    }

                    p {

                    }
                }
            }
        }
    }


}