.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 4rem 5rem;
    background-color: $color-black;
    color: white;

    &__partners {
        margin-bottom: 5rem;
        padding-bottom: 3rem;
        gap: 5rem;
        border-bottom: 1px solid $color-white;

        display: flex;
        
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &__item {
            width: 10rem;
            height: 10rem;

            &:not(:last-child) {
                // margin-right: 5rem;
            }
        }



    }

    &__main {

        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;

        @include respond(tab-land) {
            flex-direction: column;
        }

        &__left {

            display: flex;
            justify-content: flex-start;
            align-items: center;


            @include respond(tab-land) {
                justify-content: center;
                margin-bottom: 2.5rem;
                gap: 4rem;
            }

            &__imgbox {
                margin-right: 2rem;
            }

            &__img {}

            &__list {
                list-style: none;

                

                li {
                    font-size: 1.7rem;
                    margin-bottom: 2.2rem;
                    cursor: pointer;
                    transition: all .2s ease;

                    &:hover {
                        font-size: 1.9rem;
                    }

                }
            }

        }

        &__right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            @include respond(tab-land) {
                gap: 2rem;
            }

            &__contactus {
                display: flex;
                
                @include respond(phone-large) {
                    flex-direction: column;
                }

                &__ourapps {
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1.5rem;
                    border-right: 1px solid $color-white;
                    

                    @include respond(phone-large) {
                        margin-bottom: 2rem;
                    }

                    span {
                        margin-right: 1.5rem;
                    }

                    li {
                        border: 1px solid $color-white;
                        padding: 1rem;
                        border-radius: 2rem;
                        margin-right: 1.5rem;
                        cursor: pointer;
                    }
                }

                &__socials {
                    list-style: none;
                    display: flex;
                    cursor: pointer;

                    li {
                        border: 1px solid $color-white;
                        padding: 1rem;
                        border-radius: 2rem;

                        &:not(:last-child) {
                            margin-right: 1.5rem;
                        }

                    }

                }

            }

            &__icon {
                width: 3rem;
                height: 3rem;
            }


            &__copyright {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;

                @include respond(tab-land) {
                    gap: 1rem;
                }

                @include respond(tab-land) {
                    align-items: center;
                    text-align: center;
                }


                &__list {
                    list-style: none;
                    display: flex;

                    @include respond(tab-land) {
                        flex-direction: column;
                        margin-top: 3rem;
                    }

                    li {
                        padding-right: 1rem;
                        margin-right: 1rem;
                        margin-top: 1.5rem;

                        &:not(:last-child) {
                            border-right: 1px solid $color-white;

                            @include respond(tab-land) {
                                border: none;
                            }
                        }

                        @include respond(tab-land) {
                            padding: 0;
                            margin: 0;
                            
                            margin-bottom: 1.2rem;
                        }
                        
                    }
                }
            }

        }

    }
}