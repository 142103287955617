.allMatches {
    font-size: 1.5rem;
    

    &__header {
        width: 100%;
        background-color: $color-primary;
        padding: 3rem 0;
        text-align: center;

        span {
        
            font-size: 2rem;
            border-bottom: 2px solid #fff;
            color: white;
        }

    }

    

    &__body {

        padding: 5rem;

        @include respond(tab-land) {
            padding: 3rem;
        }

        @include respond(phone-large) {
            padding: 1rem;
        }

    }

    &__list {

        &__box {
            margin-bottom: 8rem;

        }

        &__head {

            margin-bottom: 3rem;

            &__year {


            } 

            &__seperator {
                padding: 0 1rem;
                font-size: 3rem;

            }

            &__month {

            }
        }

        &__fixtures {

            &__item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2rem 1rem;
                border-bottom: 1px solid #ddd;


                &__wrap {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

                &__phoneview {
                    display: none;
                    width: 0;

                    @include respond(phone-large) {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                        margin-top: 2rem;

                    }

                    p {
                        color: $color-secondary-dark;
                        font-style: italic;
                    }

                }
            }



            &__home {
                
                flex: 1;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__date {

                    @include respond(phone-large) {
                        display: none;
                        width: 0;
                    }
                }

                &__team {

                }

                img  {
                    width: 4rem;
                    height: 4rem;
                }
                    
            }

            &__midbox {
                flex: 0.25;

                display: flex;
                justify-content: center;
                align-items: center;

                @include respond(phone-large) {
                    padding: 0 1rem;
                }
            }

            &__time {
                

            }

            &__score {
                padding: 1rem 2rem;
                
                font-size: 1.5rem;
                font-weight: 700;
                background-color: $color-secondary-dark;
               
                border-radius: 5px;
                color: $color-white;


                &__myteam {
                    
                }

                &__colon {
                    margin: 0 5px;

                }

                &__awayteam {

                }
            }

            &__away {

                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__team {

                }

                img  {
                    width: 4rem;
                    height: 4rem;
                }

                &__league {

                    @include respond(phone-large) {
                        display: none;
                        width: 0;
                    }

                }

            }
        }
    }
}