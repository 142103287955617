.readblog {

    padding-top: 15vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__tags {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;

        width: 60%;

        @include respond(tab-land) {
            width: 75%;
        }

        @include respond(phone-large) {
            width: 88%;
        }

        &__item {
            
            font-weight: 500;
            margin-right: 2rem;
            margin-bottom: 2rem;
            padding: 1.5rem;
            background-color: $color-primary-light;
            color: $color-white;

        }
    }

    &__title {
        font-size: 6rem;
        text-align: center;
        margin: 3rem 0;
        width: 70%;

        @include respond(phone-large) {
            font-size: 3rem;
            width: 90%;
        }

    }

    &__summary {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        width: 50%;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 90%;
        }
    }

    &__imgbox {
        padding: 2rem 8rem;

        @include respond(tab-land) {
            padding: 2rem 5rem;
        }

        @include respond(phone-large) {
            padding: 2rem 3rem;
        }
    }

    &__img {
        width: 100%;
        height: auto;

    }

    &__text {
        padding: 2rem 8rem;
        font-size: $default-font-size;
        width: 70%;

        &__container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 3rem;

        }

        &__head  {

        }

        &__content {

        }

        @include respond(tab-land) {
            padding: 2rem 5rem;
            width: 75%;
        }

        @include respond(phone-large) {
            padding: 2rem 3rem;
            width: 90%;
        }


        &::first-letter {
            font-size: 5rem;
            color: $color-primary-dark;

        }

    }

    &__share {
        margin: 5rem 0;
        padding-top: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__header {
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 4rem;

        }

        &__iconbox {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        &__icon {
            width: 3rem;
            height: auto;
            margin-right: 2rem;

        }
    }

    &__articles {
        padding: 2rem 5rem;
        margin-top: 10rem;

        &__header {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;
           

            &__main {
                font-size: 3rem;

            }

            &__sub {
                font-size: 2rem;
                color: $color-primary-dark;

            }
        }

        &__body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            grid-column-gap: 2rem;
        }
    }

}