.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 100px;


    &__logobox {
        cursor: pointer;
    }

    &__logo {
        width: 80%;
        height: auto;

    }

    &__list {
        list-style: none;
        display: flex;

        @include respond(tab-land) {
            display: none;
            width: 0;
        }

        &>* {
            margin: 1.6rem;

        }

        &:last-child {
            margin-right: 0;
        }

        li {
            padding: 0 1rem;
            font-weight: 700;
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            list-style: none;

            p {
                display: flex;
                justify-content: center;
                align-items: center;

                z-index: 999;
                padding: 5px 0;
                transition: all .3s ease;

                &:hover {
                    border-bottom: 2px solid $color-secondary-dark;
                }
            }

            &:hover {
                color: $color-primary-dark;

            }
        }


        li:hover ul {

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // width: auto;

        }

        li:hover p {}

        &__dropdown {

            // width: 0;
            display: none;

            position: absolute;
            top: 3rem;
            left: 0;

            min-width: 160px;
            background-color: white;

            li {
                list-style: none;
                width: 100%;
                padding: 1rem;
                border-bottom: 1px solid $color-grey-dark;
                transition: all .2s none;
                // margin-bottom: 1.5rem;


                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                &:hover {
                    border-bottom: 2px solid $color-secondary-dark;
                }
            }
        }

        &__icon {
            width: 2rem;
            height: 2rem;

        }

    }

    &__account {

        &__cart {
            position: relative;


            &__value {
                position: absolute;
                top: -20px;
                right: 1px;
                padding: 5px;
                background-color: $color-secondary-dark;
                font-size: 10px;
                color: white;

            }

            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 20px;


            }

            &__price {
                margin-right: 1rem;
                font-weight: 700;


            }
        }

    }

    &__menu {

        display: none;
        width: 0;



        @include respond(tab-land) {
            display: flex;
            flex-direction: column;
            width: 30px;
        }

        &__dropdown {
            display: none;
            width: 0;

            position: absolute;
            top: 100px;
            left: 0;
            background-color: $color-white;

            z-index: 99;

            animation: drop .5s normal;


            &__container {
                list-style: none;
                display: flex;
                flex-direction: column;

                li {
                    padding: 2rem;
                    font-weight: bold;
                    font-size: 1.8rem;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;
                    list-style: none;
    
                    &:hover > ul {
                        display: block;
                        width: auto;
                    }
                    
    
                    p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
    
                }
            }

            

            &__list {

                // display: none;
                // width: 0;


                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                }

                li {
                    padding: 2rem;
                    font-weight: normal;
                    font-size: 1.6rem;
                    border-bottom: none;
                    cursor: pointer;


                }

            }
        }

        &__line {


            &>* {
                background-color: rgba($color-black, 0.8);

            }

            &--1 {
                height: 2px;

            }

            &--2 {
                height: 1px;
                margin: 7px 0;

            }

            &--3 {
                height: 2px;
            }


        }

        /*
        &__line:hover + &__dropdown {
            
            display: block;
            width: 100vw;
            background-color: $color-white;
            
        }
        */
    }
}


@keyframes dropDown {
    0% {

        transform: translateX(-10rem);

    }

    100% {
        transform: translateX(0);

    }
}