.report {

    padding: 4rem 5vw;


    height: 100%;
    background-image: linear-gradient(to bottom, rgba($color-primary-dark, 0.72), rgba($color-primary, 0.93)),
        url('https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/gallery%2F7.jpg?alt=media&token=f1f68d8a-d65f-49dc-a1ef-043cfaae04ae');
    // filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(tab-land) {
        padding: 4rem 2rem;
    }

    &__trophyicon {
        width: 4rem;
        height: 4rem;
        color: white;

    }

    &__score {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-bottom: 2rem;


        @include respond(tab-land) {
            width: 100%;
        }

        &__team {


            &__box {
                display: flex;
                justify-content: center;
                align-items: center;
                // gap: 2rem;
                width: 100%;
                flex: 1;


                @include respond(phone-large) {
                    flex-direction: column;
                }

                &__home {
                    @include respond(phone-large) {
                        flex-direction: column-reverse;
                    }
                }

                &> :first-child {
                    flex-basis: 60%;
                }

                p {
                    text-align: center;
                }
            }

            &__title {
                font-weight: bold;
                font-size: 2.5rem;
                // width: 20rem;



                overflow: hidden;

                @include respond(tab-land) {
                    font-size: 1.7rem;
                }

                @include respond(phone-medium) {
                    font-size: 1.45rem;
                }
            }

            &__badge {
                width: 120px;
                max-width: auto;
                height: auto;

                @include respond(tab-land) {
                    width: 60px;
                    height: auto;
                }

                @include respond(phone-medium) {
                    width: 40px;
                }


                &__box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
        }

        &__result {

            padding: 1rem 2rem;
            font-size: 2rem;
            background-color: $color-secondary-dark;
            font-weight: bold;
            border-radius: 1rem;
            margin: 0 4rem;

            &__myteam {}

            &__colon {
                margin: 0 1rem;
            }

            &__otherteam {}
        }
    }

    &__highlights {

        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;

        @include respond(tab-land) {
            width: 100%;
        }

        &__home {
            flex: 1;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            &__goal {
                display: flex;
                justify-content: center;
                align-items: center;

                &__name {}

                &__time {
                    margin: 0 1rem;
                    color: $color-secondary-dark;
                    font-weight: bold;
                }

                &__ball {

                    img {
                        width: 2rem;
                        height: 2rem;
                    }


                }

            }


        }

        &__away {
            align-items: flex-end;
        }


        &__stadium {
            text-align: center;
        }
    }

    &__bottom {
        width: 85%;

        justify-content: space-between;
        align-items: center;

        display: flex;

        @include respond(tab-land) {
            width: 100%;
        }

        &>* {
            flex: 1;
        }

        &__date {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__dayofmonth {
                font-size: 2.5rem;
                margin-right: 8px;
                font-weight: bold;
            }

            &__sub {
                font-size: 1.2rem;

                &__day {}

                &__month {}
            }
        }

        &__text {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
        }

        &__match {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;

            &__type {
                font-weight: bold;
                font-size: 1.3rem;
            }

            &__ref {
                font-size: 1.5rem;

                @include respond(phone-large) {
                    font-size: 1.2rem;
                }
            }
        }

    }
}