.leaguetable {
    
    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    &__table {
        // flex: 1.2;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include respond(phone-large) {
            width: 95%;
        }


        &__container {
            width: 100%;
        }

        &__head {
            display: flex;
            justify-content: space-between;
            padding: 1rem 5px;
            width: 100%;
            background-color: $color-primary-dark;
            color: $color-white;
            margin-bottom: 5px;

            &__trophy {
                display: flex;
                justify-content: center;
                align-items: center;

                &__icon {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

            &__title {}
        }

        &__row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: .8rem 0;


            &__img {
                width: 25px;
                height: 25px;
                margin: 0 1rem;
            }
        }

        &__labels {
            display: flex;
            width: 100%;

            padding: 1rem 0;
            background-color: $color-primary-dark;
            font-weight: bold;
            color: white;

            &__left {
                // flex: 0 0 30%;

                width: 30%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;

                &__badge {
                    margin-left: 5rem;
                    flex: 1;

                    @include respond(phone-large) {
                        margin-left: 0;
                    }

                    
                }

                &__name {
                    // margin-left: 4rem;
                }
            }

            &__right {
                width: 70%;

                display: flex;
                justify-content: center;
                align-items: center;



                span {

                    // width: 3rem;
                    flex: 1;
                    text-align: center;

                }

                &__pts {
                    font-weight: bold;
                }
            }

        }
    }
}