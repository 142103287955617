.fullshop {

    &__banner {
        width: 100%;
        background-color: $color-primary;
        padding: 3rem 0;
        text-align: center;

        span {

            font-size: 2rem;
            border-bottom: 2px solid #fff;
            color: white;
        }

    }

    &__body {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 5rem 8vw;

        &__item {

            margin-bottom: 2rem;
            flex: 1;

            flex-basis: 30%;
            // border: 1px solid red;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $color-grey-light-1;

            &__imgbox {}

            &__img {
                width: 300px;
                height: 400px;
            }

            &__details {

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &__title {
                    margin-bottom: 1.5rem;
                }

                &__price {

                    margin-bottom: 1.5rem;
                    color: $color-secondary-dark;

                    font-weight: 2rem;
                    font-weight: 600;
                    span {}
                }

            }


        }
    }

    &__btnsection {
        text-align: center;
        margin: 3rem 0;
    }

    &__btn {
        background-color: $color-primary-dark;
    }

}