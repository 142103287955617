.nointernet {

    

    display: flex;
    justify-content: center;
    align-items: center;


    &__container {
        width: 80%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        text-align: center;
        padding: 10rem 0;

        @include respond(phone-large) {
            gap: 1.5rem;
    
            padding: 6rem 0;
        }
    }

    img {
        width: 10rem;
        height: 10rem;

        @include respond(phone-large) {
            width: 8rem;
            height: 8rem;
        }
    }

    p {
        font-size: 2.5rem;
        font-weight: bold;

        @include respond(phone-large) {
            font-size: 2rem;
        }
    }
}