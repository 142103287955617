.staff {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 5rem 10vw;

    &__head {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;


        h3 {
            margin-bottom: 2rem;
        }

        p {}
    }

    &__container {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

    }

    &__item {
        width: 30rem;
        height: 35rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__img {
            position: relative;
            

            div {
                position: absolute;
                background-color: $color-secondary-dark;
                height: 15rem;
                width: 25rem;
                bottom: 0;
                left: 0;
                z-index: -9;
                
            }

            img {

                height: 25rem;
                width: 25rem;
                z-index: 9;

            }
        }

        &__details {

            margin-top: 1rem;
            h4 {}

            p {}
        }

    }




}